// jQuery(document).ready(function(){
//
// jQuery(document).foundation(
// );
//
// setInterval(function(){
// jQuery(".view-home-teaser-days").animate({
//     opacity: 0,
//         },0)
//         jQuery(".arrow:nth-child(3) a").click()
//         jQuery(document).ajaxComplete(function(ev) {
//           console.log('tada!', jQuery(ev.target))
//         jQuery(".view-home-teaser-days").animate({
//           opacity: 1,
//               },400)
//      })
// })
// },5000)
